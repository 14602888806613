import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "./ProminLoader.web";
import axios from "axios";
// Customizable Area Start
import { message as MESSAGE } from "antd";
import { History } from "history";
// Customizable Area End

export const configJSON = require("./config");
export const baseURLconfig = require("../../../framework/src/config");

// Customizable Area Start

export interface QuestionOption {
  id: string;
  type: string;
  attributes: {
    possible_answer: string;
    correct: boolean;
    answer_image: string | null;
    answer_video: string | null;
  };
}

export interface QuestionBankListData {
  id: string;
  type: string;
  attributes: {
    question: string;
    question_type: string;
    question_category: string;
    question_level_id: number;
    questionbank_id: number;
    question_image: string | null;
    question_video: string | null;
    options: {
      data: QuestionOption[];
    };
  };
}

interface QuestionResponse {
  error: QuestionResponse;
  questions: Array<QuestionBankListData>
}

interface GradeListDataType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface AgeListDataType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

interface AllQuestionData {
  attributes: {
    question_no: string;
    question: string;
    options: { data: [{ attributes: { possible_answer: string } }] };
    link_to_detail: string;
    correct_answer: string;
  };
}

interface ValuesData {
  grade: "";
  age: "";
}
// Customizable Area End

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    history: History;
    navigation: any;
    btnDisplay:string;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  disabled:boolean,
  showinfoData:boolean,
  answerId:any;
  questionBankId:any,
  questionId:any,
  downloadChangeModal: boolean;
  quetionSeeModal: boolean;
  quetionBankList: Array<QuestionBankListData>;
  gradeList: Array<GradeListDataType>;
  ageList: Array<AgeListDataType>;
  allQuetionDataForShowButton: Array<AllQuestionData>;
  token: string;
  allData: {
    meta: {
      total_pages: number;
    };
  };
  showInfo: boolean;
  isModalOpen: boolean;
  checkedQueue: number;
  selectedQuestion: string;
  questionBankBtn: boolean;
  sectionID: string;
  isNextBtnVisible: boolean;
  correctAnswer: any, 
  isAnswerChecked: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuetionBankListApiCallId!: string;
  getGradeListApiCallId!: string;
  getAgeListApiCallId!: string;
  getQuestionBankListAPICallId!: string;
  getDownloadTemplateAPICallId!: string;
  saveQuestion!:string;
  saveHandle!:string;
  postUploadTemplateAPICallId!: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    // Customizable Area Start
    this.state = {
      downloadChangeModal: false,
      disabled:false,
      answerId:0,
      showinfoData:false,
      questionBankId:0,
      questionId:0,
      quetionSeeModal: false,
      quetionBankList: [],
      gradeList: [],
      ageList: [],
      token: "",
      allQuetionDataForShowButton: [],
      allData: {
        meta: {
          total_pages: 0,
        },
      },
      showInfo: false,
      isModalOpen: false,
      checkedQueue: 0,
      selectedQuestion: "",
      sectionID: "",
      questionBankBtn: true,
      isNextBtnVisible: false,
      correctAnswer: "", 
      isAnswerChecked: false,
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    const token = ( localStorage.getItem("signupToken")) || "";
    this.setState({ token: token });
    localStorage.setItem("retryID",String(localStorage.getItem("sectionID")))
    const sectionID = this.props?.history?.location?.state?.topic_id;
    localStorage.setItem("sectionID", this.props?.history?.location?.state?.topic_id)
    this.setState({sectionID: sectionID})
  
    this.getQuetionBankList();
    this.getAgeList();
    this.getGradeList();
  }

  apiCall = async (data: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = data;
    const tokens =localStorage.getItem("signupToken");
    const header = {
      "Content-Type": contentType,
      token:tokens
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (apiRequestCallId === this.getDownloadTemplateAPICallId) {
        this.setState({
          downloadChangeModal: false,
        });
        let fileUrl = `${baseURLconfig.baseURL}${responseJson.meta.url}`;
        this.handleDownloadFiles(fileUrl);
      }
      if(apiRequestCallId===this.saveHandle){
       this.props.navigation.navigate("Score")
      }
      this.handleSuccessApiCall(apiRequestCallId, responseJson)
      if (responseJson.data) {
        switch (apiRequestCallId) {
          case this.getGradeListApiCallId:
            this.setState({
              gradeList: responseJson.data,
            });
            break;
          case this.getAgeListApiCallId:
            this.setState({
              ageList: responseJson.data,
            });
            break;
          case this.getQuestionBankListAPICallId:
            this.props.hideLoader();
            this.setState({
              quetionSeeModal: true,
              allQuetionDataForShowButton: responseJson.data,
              allData: responseJson,
            });
            break;
          case this.postUploadTemplateAPICallId:
            this.props.hideLoader();
            break;
          default:
            break;
        }
      } else if (responseJson && responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getQuetionBankListApiCallId:
          case this.getGradeListApiCallId:
          case this.getAgeListApiCallId:
          case this.getQuestionBankListAPICallId:
          case this.getDownloadTemplateAPICallId:
            MESSAGE.error(`${responseJson.errors[0]}.`, 4);
            break;
          case this.postUploadTemplateAPICallId:
            this.props.hideLoader();
            MESSAGE.error(`${responseJson.errors[0]}.`, 4);
            break;
          default:
            break;
        }
      }
    }
  }

  handleSuccessApiCall =(apiRequestCallId: string, responseJson: QuestionResponse) => {
    if (apiRequestCallId === this.getQuetionBankListApiCallId) {
      
      if(responseJson && responseJson.error){
        let answer=localStorage.getItem("courseId");
        this.props.history.push(`/Course/${answer}` )
      }
        
     else{  
      this.setState({
        quetionBankList: responseJson?.questions,
      })
    }
    }
  }

  getQuetionBankList = async () => {
    let sectionid = localStorage.getItem("sectionID");
    if (!sectionid || sectionid === "undefined") {
        sectionid = (localStorage.getItem("retryID"));
        localStorage.setItem("sectionID",String(localStorage.getItem("retryID")))
    }
    this.getQuetionBankListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.questionBankEndPoint}topic_id=${sectionid}`
    });
  };

  getGradeList = async () => {
    this.getGradeListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.gradeAPiEndPoint,
    });
  };

  getAgeList = async () => {
    this.getAgeListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.ageListAPiEndPoint,
    });
  };

  getallQuestion = async (page: number) => {
    this.getQuestionBankListAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.questionBankObjectListAPiEndPoint}${page}`,
    });
  };

  downloadTemplate = async (values: { grade: ""; age: "" }) => {
    this.props.showLoader();
    this.getDownloadTemplateAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.downloadTemplateAPiEndPoint}${values.grade}&age_group_id=${values.age}`,
    });
  };

  handleDownloadFiles = async (data: string) => {
    axios({
      url: data,
      method: "GET",
      responseType: "blob",
      headers: {
        token: `${this.state.token}`,
      },
    }).then((response) => {
      const urll = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urll;
      link.setAttribute("download", "sample_file.xlsx");
      document.body.appendChild(link);
      link.click();
      this.props.hideLoader();
    });
  };

  handlePageClick = (events: { selected: number }) => {};

  onFinish = async (values: ValuesData) => {
    if (this.validateForm()) {
      this.downloadTemplate(values);
    }
  };

  validateForm = () => {
    let isValid = true;
    return isValid;
  };

  downloadChangeModal = () => {
    this.setState({
      downloadChangeModal: true,
    });
  };

  downloadChangeModalCancel = () => {
    this.setState({
      downloadChangeModal: false,
    });
  };

  quetionSeeModal = (quetionBankListItem: { attributes: { id: number } }) => {
    this.props.showLoader();
    setTimeout(() => {
      this.getallQuestion(quetionBankListItem.attributes.id);
    }, 1500);
  };

  quetionSeeModalCancel = () => {
    this.setState({
      quetionSeeModal: false,
    });
  };

  fileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.showLoader();
    let file = event.target.files?.[0] || null;
    const fileDetailes = file as unknown as Blob;
    let formdata = new FormData();
    formdata.append("name", "file.csv");
    formdata.append("file", fileDetailes);
    formdata.append("chapter", "Maths");
    this.postUploadTemplateAPICallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.uploadTemplateAPiEndPoint}`,
      body: formdata,
      type: "formData",
    });
  };

  onChangeCall = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.fileHandler(event);
  };

  handleIconHover = () => {
    this.setState({ showInfo: true });
  };

  handleIconLeave = () => {
    this.setState({ showInfo: false });
  };
  handleHover(){
    if(!this.state.selectedQuestion){
    this.setState({showinfoData:true})
    }
    else{
      this.setState({showinfoData:false})
    }
  }
  handleUnhover(){
    this.setState({showinfoData:false})
  }
  handleNextClick = async() => {
    localStorage.setItem("redirect","no")
    this.setState({selectedQuestion:'', isNextBtnVisible: false,
      isAnswerChecked: false,
      correctAnswer: "" })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: `${this.state.token}`,
    };
    const httpBody = {
      questionbank_id: this.state.questionBankId,
      question_answer_attributes: [
        {
          question_id: this.state.questionId,
          selected_option_ids: [
            this.state.answerId
          ]
        }
      ]
    }
    const getQuestion = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveQuestion = getQuestion.messageId;

    getQuestion.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}`
    );

    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getQuestion.id, getQuestion);
    await this.handleNextQuestion();
  }
    async handleNextQuestion  (){
      this.setState({disabled:false})
      const { checkedQueue, quetionBankList } = this.state;
      if (checkedQueue < quetionBankList?.length - 1) {
        this.setState((prevState) => ({
          checkedQueue: prevState.checkedQueue + 1,
        }));
      } else {
          this.finalAssessment() 
      }
  };
  finalAssessment(){
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: `${this.state.token}`,
    };
    const httpBody = {
      questionbank_id: this.state.questionBankId,
    }
    const getQuestion = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveHandle = getQuestion.messageId;

    getQuestion.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointAssessment}`
    );

    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    getQuestion.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getQuestion.id, getQuestion);
    
  }
  goBack = () => {
    this.props.navigation.goBack();
  };

  handleLeaveQuestion = () => {
    this.props.navigation.goBack();
  };
  handleModalOpen=()=>{
    this.setState({isModalOpen:true})
  }
  handleLeave = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleQuestionChange = (event: any,id:any) => {
    this.setState({disabled:true,answerId:Number(id),questionBankId:Number(this.state.quetionBankList?.[this.state.checkedQueue]?.attributes?.questionbank_id),questionId:Number(this.state.quetionBankList?.[this.state.checkedQueue]?.id)})
    const selectedJourneyName = event.target.value;
    this.setState({ selectedQuestion :selectedJourneyName, questionBankBtn: false, isNextBtnVisible: false} )
  };
  checkAnswer = () => {
    const { checkedQueue, quetionBankList } = this.state;
    const currentQuestion = quetionBankList[checkedQueue];
    const correctAnswer = currentQuestion.attributes.options.data.find((option) => option.attributes.correct)?.attributes.possible_answer;
    this.setState({
      correctAnswer,
      isAnswerChecked: true,
      isNextBtnVisible: true, // Show the Next button after checking
    });

  };
}

// Customizable Area End
