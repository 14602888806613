import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  Done, RadioButtonUnchecked
} from "@material-ui/icons";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";

interface Lesson {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    image: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
  };
}
interface Section {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    marked_as_completed: any;
    description: string;
    image: {
      url: string;
    } | null;
    video: {
      url: string;
    } | null;
    audio: {
      url: string;
    } | null;
    table: {
      url: string;
    } | null;
    lessons: {
      id: number;
      type: string;
      data: Lesson[]
    };
    completed?: boolean;
  };
}
interface Topic {
  id: number;
  name: string;
  description: string;
  assessment_attempted: any;
  image?: string | null;
  sub_category_id: any;
  sections: {
    data: Section[];
  };
  checked: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  unitId: number | null;
  currentTopic: Topic;
  data: {
    unit_name: string;
    course_name: string;
    journey_name: string;
    topics: Topic[];
  } | null;
  loading: boolean;
  currentCheckConsider:boolean;
  isTicked:boolean;
  error: string | null;
  currentSectionIndex: number;
  scrollPercentages: number;
  qb: boolean;
  isPlaying: boolean;
  open: boolean;
  isPlayingLess: boolean;
  playingVideos:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UnitController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getUnitCallId: any;
  videoRef: HTMLVideoElement | null = null;
  videoRefLess: HTMLVideoElement | null = null;
  fetchSaveMarkAsComplete: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      unitId: null,
      currentTopic: {
        id: 0,
        name: "",
        description: "",
        sub_category_id: 0,
        assessment_attempted: false,
        image: null,
        sections: {
          data: [
            {
              id: "",
              type: "",
              attributes: {
                id: 0,
                name: "",
                marked_as_completed: false,
                description: "",
                image: {
                  url: "",
                },
                video: {
                  url: "",
                },
                audio: {
                  url: "",
                },
                table: {
                  url: "",
                },
                lessons: {
                  id: 0,
                  type: "",
                  data: [
                    {
                      id: "",
                      type: "",
                      attributes: {
                        id: 0,
                        name: "",
                        description: "",
                        image: {
                          url: "",
                        },
                        video: {
                          url: "",
                        },
                        audio: {
                          url: "",
                        },
                        table: {
                          url: "",
                        },
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        checked: false,
      },
      data: null,
      loading: false,
      error: null,
      currentSectionIndex: 0, 
      isTicked:false,
      currentCheckConsider:false,
      scrollPercentages: 6,
      qb: false,
      isPlaying: false,
      open: true,
      isPlayingLess: false,
      playingVideos:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    window.scrollTo(0, 0);
    const unitId = this.props.navigation.getParam("unitId", null);
   
    const token = await getStorageData("signupToken");
    this.setState({ token: token, unitId: unitId }, () => {
      localStorage.setItem("topicid",unitId)
      this.getUnitDetails(unitId, token);
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getUnitCallId) {
      if (responseJson?.course_name) {
        this.updateTopicProgress(responseJson);
       
        let nextSectionIndex: number | 0 = 0;
        const nextTopic = responseJson?.topics.find((topic: any) =>
          topic?.sections?.data?.some((section: any, index: number) => {
            if (section?.attributes?.marked_as_completed === false) {
             
              nextSectionIndex = index;
              return true;
            }
            return false;
          })
        );
        this.setState({
          loading: false,
          data: responseJson,
          currentTopic: nextTopic || responseJson?.topics[Number(localStorage.getItem("currentTopicId"))],
          currentSectionIndex: nextSectionIndex !== null ? nextSectionIndex : 0,
        });
      }
    } else if (apiRequestCallId === this.fetchSaveMarkAsComplete) {
      console.log(responseJson, 'currentSection');
      if(responseJson?.message=='Completion data saved successfully'){
         this.setState({isTicked:true})
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  renderStatus = (currentSection: any) => {
    const { currentCheckConsider, isTicked } = this.state;
    const isCompleted = currentCheckConsider ? isTicked : currentSection?.attributes?.marked_as_completed;
  
    return (
      <>
        {isCompleted ? (
          <>
            <Done style={{ color: "#72EFDD" }} />
            <span style={{ color: "white" }}>Completed</span>
          </>
        ) : (
          <>
            <RadioButtonUnchecked style={{ color: "white" }} />
            <span style={{ color: "white" }}>Mark as completed</span>
          </>
        )}
      </>
    );
  };

  handleDisabled(){
    const {
      currentSectionIndex,
      currentTopic,
    } = this.state;
    const currentSection =currentTopic?.sections?.data[currentSectionIndex || 0];
    if (this.state.currentCheckConsider) {
      return this.state.isTicked;
    } else {
      return currentSection?.attributes?.marked_as_completed;
    }    
  }
  
  updateTopicProgress = (responseJson: any) => {
    
    const currentTopicId = localStorage.getItem("currentTopicId") || "0";
    const isLast = responseJson?.topics?.length - 1 === Number(currentTopicId);
  
    localStorage.setItem("currentTopicId", currentTopicId);
    localStorage.setItem("lastTopic", String(isLast));
  }

  goBackfromUnit=()=>{
    const courseID = this.state.data?.topics[0].sub_category_id;
    this.props.navigation.navigate("Course", {courseID})
  }
   getUnitDetails = (unitId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUnitCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.setState({ loading: true });
  };
  handleSectionChange = (index: number) => {

    this.setState({ currentSectionIndex: index });
    this.handleTest()
  };
  getBackgroundColor = () => {
    const {
      currentTopic,
      currentSectionIndex,
    } = this.state;
    const currentSection =
    currentTopic?.sections?.data[currentSectionIndex || 0];
    if (this.state.currentCheckConsider) {
      if (!this.state.isTicked) {
        return "transparent";
      } else {
        return "#72EFDD33";
      }
    } else {
      if (!currentSection?.attributes?.marked_as_completed) {
        return "transparent";
      } else {
        return "#72EFDD33";
      }
    }
    
  };
  handleVideoToggleFinal2= (lessonValue: string, isStopped: boolean) => {
    this.setState((prevState:any) => ({
      playingVideos: {
        ...prevState.playingVideos,
        [lessonValue]: !isStopped,
      },
    }));
  };
  handleVideoToggleFinal= (lessonId: string, isPaused: boolean) => {
    this.setState((prevState) => ({
      playingVideos: {
        ...prevState.playingVideos,
        [lessonId]: !isPaused,
      },
    }));
  };

  

  handleNavigation = (direction: "prev" | "next") => {
    const { currentSectionIndex, currentTopic } = this.state;
    const totalSections = currentTopic?.sections?.data.length || 0;

    if (direction === "next" && currentSectionIndex < totalSections - 1) {
      this.setState({
        currentSectionIndex: currentSectionIndex + 1,
        scrollPercentages: 0,
      });
    }  else if (direction === "prev" && currentSectionIndex > 0) {
      this.setState({
        currentSectionIndex: currentSectionIndex - 1,
        scrollPercentages: 0,
      });
    }
  };

  handleTest = () => {
    console.log("for testing purpose")
  }

  handleNavigationNext = (direction: "prev" | "next",data:any) => {
    this.setState({isTicked:false})
    let uniqueID = data.sections?.data[0].attributes;
    const { currentSectionIndex, currentTopic } = this.state;
    const totalSections = currentTopic?.sections?.data.length || 0;

    if (direction === "next" && currentSectionIndex < totalSections - 1) {
      this.setState({
        currentSectionIndex: currentSectionIndex + 1,
        scrollPercentages: 0,
      });
    }  else if (direction === "prev" && currentSectionIndex > 0) {
      this.setState({
        currentSectionIndex: currentSectionIndex - 1,
        scrollPercentages: 0,
      });
    } else {
      this.props.history.push("/QuestionBank", uniqueID )
    }
  };

  handleSectionCompletion = (currentSection: any) => {
    if(!currentSection?.attributes?.marked_as_completed){
      this.setState({currentCheckConsider:true})
    }
    else{
      this.setState({currentCheckConsider:false})
    }
    const { data, currentTopic, currentSectionIndex } = this.state;

    if (!data?.topics) return;

    const updatedData = { ...data };
    const topicIndex = updatedData.topics.findIndex(
      (topic) => topic.id === currentTopic.id
    );
    localStorage.setItem("topicRightId",String(currentTopic.id))
    if (topicIndex !== -1) {
       currentSection =
        updatedData.topics[topicIndex].sections.data[currentSectionIndex];
      currentSection.attributes.completed = !currentSection.attributes.completed;

      const allSectionsCompleted = updatedData.topics[
        topicIndex
      ].sections.data.every((section) => section.attributes.completed);

      updatedData.topics[topicIndex].checked = allSectionsCompleted;

      this.setState({
        data: updatedData,
        currentTopic: updatedData.topics[topicIndex],
        scrollPercentages: currentSection.attributes.completed ? 100 : 0,
      });
    }
    this.fetchMarkAsComplete(currentSection)
  };

  fetchMarkAsComplete = (currentSection: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      "completion": {
        "category_id": currentSection.category_id,
        "sub_category_id": currentSection.sub_category_id,
        "unit_id": currentSection.unit_id,
        "topic_id": currentSection.topic_id,
        "section_id": currentSection.id
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSaveMarkAsComplete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MarkAsCompleteEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePlayClick = (lessonId: string) => {
    this.setState((prevState) => ({
      playingVideos: {
        ...prevState.playingVideos,
        [lessonId]: true,
      },
    }), () => {
      const videoElement = document.getElementById(`video-${lessonId}`) as HTMLVideoElement;
      if (videoElement) {
        videoElement.play();
      }
    });
  };

  handlePlayClickLess = () => {
    if (this.videoRefLess) {
      if (this.videoRefLess.paused) {
        this.videoRefLess.play();
        this.setState({ isPlayingLess: true });
      } else {
        this.videoRefLess.pause();
        this.setState({ isPlayingLess: false });
      }
    }
  };
  
  handleVideoToggle = (isPlaying: boolean) => {
    this.setState({ isPlaying: !isPlaying });
  };
  handleVideoToggleLess = (isPlaying: boolean) => {
    this.setState({ isPlayingLess: !isPlaying });
  };
  // Customizable Area End
}
