
import React from "react";
import { Box } from "@material-ui/core";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";

interface Lesson {
  id: string;
  attributes: {
    completed?: boolean;
  };
}

interface TopicProgressProps {
  lessons: Lesson[];
  currentIndex: number;
  scrollProgress: number;
}



const ProgessBar: React.FC<TopicProgressProps> = ({
  lessons,
}) => {
  return (
    <Box className="topic-progress" style={styles.progressWrapper}>
      <Box style={styles.innerWrapper}>
        <Box style={styles.progressContainer}>
          <KeyboardArrowLeftOutlined
            data-testid="prev-button"
            style={{
              ...styles.navIcon,
              cursor: "not-allowed",
              opacity:  0.1,
            }}
           
          />

          {lessons?.map((lesson, index) => (
            <Box
              key={lesson.id}
              style={styles.progressBar}
              data-testid="progress-bar-1"
            >
              <Box
                style={{
                  ...styles.progressTrack,
                  height:"7px"
              
              
                }}
              >
                <Box
                  data-testid={`progress-bar-${index}`}
                  style={{
                    ...styles.progressFill,
                    width: '100%',
                    backgroundColor: "#72EFDD"
                  }}
                />
              </Box>
            </Box>
          ))}

          <KeyboardArrowRightOutlined
            data-testid="next-button"
            style={{
              ...styles.navIcon,
              cursor:
                "not-allowed",
              opacity: 0.1,
            }}
           
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  progressWrapper: {
    padding: "20px 0",
    backgroundColor: "transparent",
    width: "100%",
  },
  innerWrapper: {
    margin: "0 auto",
    maxWidth: "850px",
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "space-between",
  },
  progressTrack: {
    backgroundColor: "#334155",
    overflow: "hidden",
    borderRadius: "10px",
  },
  progressBar: {
    width: "100%",
  },
  progressFill: {
    height: "100%",
    borderRadius: "2px",
    transition: "width 0.3s ease-out",
  },
  navIcon: {
    color: "white",
    transition: "opacity 0.3s ease",
    fontSize: "24px",
  },
};

export default ProgessBar;
