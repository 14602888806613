import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
interface Station {
  label: string;
  score: number;
  color: string;
  x: number;
  y: number;
  completed: boolean;
  description?: string;
  order?: number;
}
interface UnitPercentage {
  unit_name: string;
  completion_percentage: string;
}
interface QuestionBankScore {
  unit_name: string;
  score: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  showFinalPath: boolean;
  stations: Station[];
  pathRef: React.RefObject<SVGPathElement>;
  courseDetails: {
    expected_duration: string;
    learners_enrolled: number;
    last_update: string;
    score: number
  };
  subCategoryData: any;
  thumbnail: string;
  unitPercentages: UnitPercentage[];
  questionBankScore: QuestionBankScore[];
  selectedStation: number | null;
  stationRefs: React.RefObject<SVGCircleElement>[];
  popupPosition: {
    top: number;
    left: number;
    maxWidth: string;
    position: any;
    transform: string;
    zIndex: number;
  } | null;
  courseID: number | null;
  certificateScore: string | null;
  open: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CoursePageController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getSubCategoryCallId: any;
  getCourseDetailsCallId: any;
  getUnitIdData:any;
  getUnitPercentageCallIds: string[] = [];
  getQueBankScoreCallIds: string[] = [];
  unitIds: number[] = [];
  getCertificateCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      showFinalPath: false,
      stations: [
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 290,
          y: 10,
          completed: false,
          order: 1,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#FFB74D",
          x: 403,
          y: 177,
          completed: false,
          order: 2,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 136,
          y: 177,
          completed: false,
          order: 3,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#FF7875",
          x: 290,
          y: 343,
          completed: false,
          order: 4,
          description: "Loading...",
        },
        {
          label: "Loading...",
          score: 0,
          color: "#4CD6C9",
          x: 403,
          y: 510,
          completed: false,
          order: 5,
          description: "Loading...",
        },
      ],
      pathRef: React.createRef(),
      courseDetails: {
        expected_duration: "",
        learners_enrolled: 0,
        last_update: "",
        score:0
      },
      subCategoryData: null,
      thumbnail: "",
      unitPercentages: [],
      questionBankScore: [],
      selectedStation: null,
      stationRefs: Array(5)
        .fill(null)
        .map(() => React.createRef()),
      popupPosition: null,
      courseID: null,
      certificateScore: null,
      open: false,
   
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const courseID = this.props.navigation.getParam("courseID", null);
    const token = await getStorageData("signupToken");
    this.setState({ token: token, courseID: courseID }, () => {
      this.getCourseDetails(courseID, token);
      this.getSubCategoryData(courseID, token);
      this.checkCertification(courseID)
      localStorage.setItem("courseId",courseID)
    });
    this.updatePathProgress();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    localStorage.setItem("currentTopicId", "0");
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getCourseDetailsCallId) {
      if (responseJson?.expected_duration) {
        this.setState({
          courseDetails: responseJson,
        });
      }
    }

    if (apiRequestCallId === this.getSubCategoryCallId) {
      if (responseJson?.data) {
        const units = responseJson.data.attributes.units.data;
        const updatedStations = [...this.state.stations];
        this.getUnitPercentageCallIds = [];
        this.getQueBankScoreCallIds = [];
        this.unitIds = [];
        units.forEach((unit: any, index: number) => {
          if (index < 5) {
            updatedStations[index] = {
              ...updatedStations[index],
              label: unit.attributes.name,
              description: unit.attributes.description,
            };
            this.unitIds[index] = unit.attributes.id;
            this.getUnitPercentage(unit.attributes.id);
            localStorage.setItem("unitId",unit.attributes.id)
            this.getQuestionBankScore(unit.attributes.id);
          }
        });
        localStorage.setItem("AllUnits", JSON.stringify(this.unitIds));
        console.log(responseJson.data, "responseJson.data")
        this.setState({
          subCategoryData: responseJson.data,
          stations: updatedStations,
          thumbnail: responseJson.data.attributes.image.url,
          unitPercentages: [],
          questionBankScore: [],
        });
      }
    }
    if (this.getUnitPercentageCallIds.includes(apiRequestCallId)) {
      if (responseJson?.completion_percentage) {
        this.setState((prevState) => {
          const newUnitPercentages = [
            ...prevState.unitPercentages,
            responseJson,
          ];

          const updatedStations = prevState.stations.map((station) => {
            if (responseJson.unit_name === station.label) {
              return {
                ...station,
                completed:
                  parseInt(responseJson.completion_percentage) == 100,
              };
            }
            return station;
          });

          return {
            unitPercentages: newUnitPercentages,
            stations: updatedStations,
          };
        });
      }
    }

    // For question bank scores
    if (this.getQueBankScoreCallIds.includes(apiRequestCallId)) {
      if (responseJson?.score) {
        this.setState((prevState) => {
          return {
            questionBankScore: [...prevState.questionBankScore, responseJson],
          };
        });
      }
    }
    if (apiRequestCallId === this.getCertificateCallId) {
      if (responseJson?.question_bank_score) {
        this.setState({
          certificateScore: responseJson.question_bank_score,
        });
      }
    }
    if (apiRequestCallId === this.getUnitIdData) { 
      if (responseJson) {
        async function determineNavigation  (responseJson: any) {
          let lastSectionId: any = null; 
        
          for (const topic of responseJson?.topics) {
            let questionID=topic.id;
            for (const section of topic?.sections?.data || []) {
              lastSectionId = section?.attributes.id;    
              if (section?.attributes?.marked_as_completed === false) {
                return { path: '/Unit', sectionId: section?.attributes.id };
              }
            }
              if (topic?.assessment_attempted === false) {
                return { path: '/Score', sectionId: questionID};
              }
            }
          return { path:'/',sectionId: lastSectionId };
        };
         const nextRoute = await  determineNavigation(responseJson);
         if (typeof nextRoute === "object" && nextRoute !== null) {
          if (nextRoute.path === "/Score") {
            let uniqueID=nextRoute.sectionId;
            let rightid=localStorage.getItem("unitIdNavigation")
            await Promise.resolve(localStorage.setItem("sectionID", uniqueID));
            await Promise.resolve(localStorage.setItem("retryID", ""));
       
            await Promise.resolve(localStorage.setItem("topicid", String(rightid)));
            this.props.navigation.navigate("QuestionBank")           
          }

          else if (nextRoute.path === "/Unit"|| nextRoute.path =='/') {
            let unitId = localStorage.getItem("unitIdNavigation")
            this.props.navigation.navigate("Unit", { unitId })
          }
        }
      }} 
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate() {
    this.updatePathProgress();
  }

  updatePathProgress = () => {
    const path = this.state.pathRef.current;
    if (path) {
      const length = path.getTotalLength();
      path.style.strokeDasharray = length.toString();

      const lastCompletedIndex = this.state.stations
        .reduce((acc, station, index) => 
          station.completed &&
        this.state.questionBankScore.some(
          unit => unit.unit_name === station.label && unit.score.all_attempted
        )
          ? index
          : acc,
          -1);

      const nextStation = this.state.stations[lastCompletedIndex + 1];
      if (!nextStation) {
        path.style.strokeDashoffset = "0";
        return;
      }

      let accumulatedLength = 0;
      for (let i = 0; i <= length; i++) {
        const point = path.getPointAtLength(i);
        if (
          Math.abs(point.x - nextStation.x) < 1 &&
          Math.abs(point.y - nextStation.y) < 1
        ) {
          accumulatedLength = i;
          break;
        }
      }

      path.style.strokeDashoffset = (length - accumulatedLength).toString();
    }
  };

  createMainPath = () => {
    const radius = 80;
    let path = `M ${this.state.stations[0].x} ${this.state.stations[0].y}`;
    path += ` L 450 ${this.state.stations[0].y}`;
    path += ` A ${radius} ${radius} 0 0 1 450 ${this.state.stations[1].y}`;
    path += ` L 100 ${this.state.stations[1].y}`;
    path += ` A ${radius} ${radius} 0 0 0 100 ${this.state.stations[3].y}`;
    path += ` L 290 ${this.state.stations[3].y}`;
    path += ` L 450 ${this.state.stations[3].y}`;
    path += ` A ${radius} ${radius} 0 0 1 450 ${this.state.stations[4].y}`;
    path += ` L 390 ${this.state.stations[4].y}`;
    return path;
  };

  getCourseDetails = (courseID: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCourseDetailsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.coureseDetailsEndpoint}=${courseID}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getSubCategoryData = (courseID: number, token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSubCategoryCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategoryEndpoint}=${courseID}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getUnitPercentage = (unitId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = apiRequest.messageId;

    // Track this specific call ID
    this.getUnitPercentageCallIds.push(callId);

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitPercentagesEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };
  getQuestionBankScore = (unitId: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = apiRequest.messageId;

    // Track this specific call ID
    this.getQueBankScoreCallIds.push(callId);

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.questionBankScoreEndpoint}=${unitId}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  calculatePopupPosition = (index: number) => {
    const circleRef = this.state.stationRefs[index];
    if (!circleRef.current) return null;

    const circle = circleRef.current;
    const circleRect = circle.getBoundingClientRect();
    const popupWidth = 350;
    const popupOffset = 20;

    return {
      position: "absolute" as const,
      top: circleRect.top + circleRect.height + popupOffset + window.scrollY,
      left: circleRect.left + circleRect.width / 2 - popupWidth / 2,
      maxWidth: `${popupWidth}px`,
      transform: "translate3d(0, 0, 0)",
      zIndex: 1001,

    };
  };

  handleStationClick = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    const newPosition = this.calculatePopupPosition(index);

    this.setState({
      selectedStation: this.state.selectedStation === index ? null : index,
      popupPosition: newPosition,
    });
  };

  unitTxt = (): string => {
    if (this.state.selectedStation === null) return "Start Unit!";

    const selectedUnit = this.state.stations[this.state.selectedStation];
    const unitPercentage = this.state.unitPercentages.find(
      (up) => up.unit_name === selectedUnit.label
    )?.completion_percentage;

    let newValue = this.state.stations.map((station, index) => {
      const questionBankScore = this.state.questionBankScore.find(
        (qs) => qs.unit_name === station.label
      );
      const isScored2 = questionBankScore?.score?.all_attempted;
      return isScored2; 
    });
    const allScored = newValue.every(value => value === true)

    if (unitPercentage && parseInt(unitPercentage) > 0) {
      if (parseInt(unitPercentage) < 100 || !allScored) {
        // if this part then handle question bank and sections-- 
        return newValue[this.state.selectedStation] ? "Review" : "Continue Unit";;
      }
      return "Review";
    } else {
      return "Start Unit!";
    }
  };
  getUnitData = (unitId: number, token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    const apiRequestData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUnitIdData = apiRequestData.messageId;

    apiRequestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unitEndpoint}=${unitId}`
    );

    apiRequestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequestData.id, apiRequestData);
   
  };
  async handleNavigation (unitId: number){
    localStorage.setItem("unitIdNavigation",String(unitId))
    const token =  await getStorageData("signupToken");
    this.getUnitData(unitId,token)
  }

  onJourney = () => {
    const {navigation} = this.props;
    const id = this.state.subCategoryData?.attributes.units.data[0].attributes.category_id
    if(navigation) {
      navigation.navigate('Journey', {id})
    }
  };

  checkCertification = (courseID: number) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCertificateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.certificateEndpoint}=${courseID}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };
  // Customizable Area End
}
