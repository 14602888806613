import React from "react";
// Customizable Area Start
import '../../catalogue1/src/Header.css'
import {
    Box, CircularProgress, Typography,

    ListItemIcon,
    ListItemText,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    CssBaseline,
} from "@material-ui/core";

import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import Header from "../../catalogue1/src/Header.web";
import "../../scheduling/src/global.css";
import ScoreController, { Props } from "./ScoreController.web";
import { exam, section, lessons,correct,incorrect, skip_exam2 } from "./assets";
import UnitProgress from "./UnitProgress.web";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';

import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const closeWidth = "60px"; // Width when drawer is closed
const openWidth = "27vw";
const styles = {
    root: {
        display: "flex",

    },

    menuButton: {
        marginRight: "20px",
    },
    hide: {
        display: "none",
    },
    show: {
        display: "block",
    },
    appBar: {
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    appBarShift: {

        width: `calc(100% - ${openWidth})`,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1), margin 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    contentShift: {
        marginLeft: openWidth, // Shift when drawer is open
    },
    drawerOpen: {
        width: openWidth,
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
    },

    content: {
        flexGrow: 1,
        padding: "30px",
        transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1)",
        marginLeft: closeWidth, // Start with closed drawer width
    },
   

    drawerPaper: {

    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0px ",
        justifyContent: "flex-end",
    },
 
    drawerClose: {
        overflowX: "hidden",
        transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1)",
        width: closeWidth,
    },
};

// Customizable Area End
export default class ViewUnitResult extends ScoreController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderProgressBar() {
        const {

            currentLessonIndex,
            currentSection,
        } = this.state;

        return (
            <UnitProgress data-test-id="handleNavigate"
                lessons={currentSection?.sections?.data || []}
                currentIndex={currentLessonIndex}
                scrollProgress={100} />)
    }


    renderView() {

        const { loading, error } = this.state;
        if (loading) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    height="100vh"
                    justifyContent="center"
                    width="100%"
                >
                    <CircularProgress style={{ color: "white" }} />
                </Box>
            );
        }

        if (error) {
            return (
                <Typography variant="h6" style={{ color: "red" }}>
                    {error} </Typography>
            );
        }
        return (
            <Box style={webStyle.mainContent}>
                <>
                    <style>
                        {`
                .preview-font {
                    font-family: 'Poppins', sans-serif !important;
                }
                .mainDescription p, .mainDescription span {
                  color: white !important;
                  font-size: 20px;
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                }
                .preview-font span {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  color: #fff !important;
                }
                .scroll-container::-webkit-scrollbar {
                  display: none;
                }
                .preview-font p {
                  background: transparent !important;
                  font-family: 'Poppins', sans-serif !important;
                  clear: left;
                }
                .preview-font img {
                  float: right;
                  margin: 0 0 15px 15px;
                  border-radius: 10px;
                  height: 200px;
                  width: 250px;
                  object-fit: fill;
                }
                .MuiPaper-root.MuiPaper-rounded{
                  min-width: auto !important;
                  border-radius: 50px;
                  scrollbar-width: none;
                }
            `}
                    </style>
                </>
                <Box style={webStyle.content}>
                    <Box style={webStyle.courseName}>
                        <Typography

                            style={{ fontSize: "24px",fontWeight:'bold' }}
                            variant="h2"
                            data-testid="unit-title"
                        >
                            {this.state.data?.unit_name}
                        </Typography>
                        <Typography
                            style={{ fontSize: "24px" }}
                            variant="h2"
                            data-testid="unit-title"
                        >
                            Unit Overview
                        </Typography>
                    </Box>
                    {this.renderProgressBar()}
                    <Box style={webStyle.container} className="scroll-container">
                        <Box style={webStyle.titleContainer}><Typography variant="subtitle1" style={webStyle.title}>
                            Unit Results
                        </Typography>
                        </Box>
                        <div style={webStyle.wholeContent}>
                            Well done! You've finished all the content for this unit.
                        </div>
                        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img style={{ marginTop: '5px', marginRight: '8px' }} src={section} />
                                <div style={webStyle.question}>
                                    {this.state.scoreUnit?.total_topics} topics
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img style={{ marginTop: '5px', marginRight: '8px' }} src={lessons} />
                                <div style={webStyle.question}>
                                    {this.state.scoreUnit?.total_sections} sections
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img style={{ marginTop: '5px', marginRight: '8px' }} src={exam} />
                                <div style={webStyle.question}>
                                    {this.state.scoreUnit?.total_questions} questions
                                </div>
                            </div>
                        </div>
                        <Box style={{ ...webStyle.subcontainer }} className="scroll-container">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ color: '#64748B', fontSize: '14px', fontWeight: 700, fontFamily: 'Raleway', }}>
                                    YOUR FINAL GRADE:
                                </div>
                                <div data-test-id="viewmore" onClick={() => this.viewMore()} style={{ color: '#334155', fontSize: '14px', fontFamily: 'Raleway', textDecoration: 'underline', cursor: 'pointer' }}>
                                    {this.state.detailsShow}
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <div
                                    style={{
                                        ...webStyle.questionScore,
                                        backgroundColor: this.handleColorUnit(),
                                        marginRight: '10px',

                                    }}
                                >
                                    {this.state.scoreUnit?.score?.score}/100
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '6px', marginLeft: '3px' }}>

                                    <div style={{ ...webStyle.questionMessage, marginTop: this.state.SectionData?.score < 70 ? '10px' : '20px' }}>
                                        {this.state.scoreUnit?.message}
                                    </div>
                                    {this.state.scoreUnit?.score?.score < 70 &&

                                        <div style={{ ...webStyle.questionMessage, color: '#334155B2' }}>
                                            Remember, you can always go back and aim for a better grade!
                                        </div>
                                    }
                                    {this.state.scoreUnit?.score?.score >= 70 &&

                                        <div style={{ ...webStyle.questionMessage, color: '#334155B2' }}>
                                            You can always go back and practice more to solidify your knowledge!
                                        </div>
                                    }
                                </div>
                            </div>
                           
                            {this.state.openMore && (
                                <>
                                    <Divider style={{ backgroundColor: 'white', marginTop: '20px' }} />
                                    {this.state.scoreUnit?.topic_details?.map((topic: any, index: any) => (
                                         <>
                                            <div key={index} style={{ display: 'flex', alignItems: 'flex-start',marginBottom:'30px' }}>
                                           
                                         
                                            <div
                                                style={{
                                                    ...webStyle.questionScore,
                                                    backgroundColor: this.handleColorSections(topic?.topic_score),
                                                    marginRight: '10px',
                                                }}
                                            >
                                                {topic?.topic_score}/100
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    marginTop: '6px',
                                                    marginLeft: '3px',
                                                }}
                                            >
                                                 <div style={{ ...webStyle.question, color: '#334155B2' ,fontWeight:'bold'}}>
                                                {`TOPIC ${index+1}:`}
                                            </div>
                                                <div style={{ ...webStyle.questionMessage, color: '#475569' }}>
                                                    {topic?.topic_name}
                                                </div>
                                                <div style={{ display: 'flex', gap: '16px', alignItems: 'center', }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            style={{ marginTop: '8px', marginRight: '4px' }}
                                                            src={section}
                                                            alt="Section"
                                                        />
                                                        <div style={{...webStyle.question,fontSize:'14px'}}>{topic?.topic_total_questions} questions:</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center',marginTop:'2px' }}>
                                                        <img
                                                            style={{ marginTop: '6px', marginRight: '4px' }}
                                                            src={correct}
                                                            alt="correct"
                                                        />
                                                        <div style={{...webStyle.question,fontSize:'11px',fontFamily:'poppins'}}>{topic?.correct_count} CORRECT</div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center',marginTop:'2px' }}>
                                                        <img
                                                            style={{ marginTop: '6px', marginRight: '4px' ,}}
                                                            src={incorrect}
                                                            alt="incorrect"
                                                        />
                                                        <div style={{...webStyle.question,fontSize:'11px',fontFamily:'poppins'}}>{topic?.incorrect_count} INCORRECT</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    ))}
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box style={webStyle.btnBox}>
                        <button data-test-id="handleLessonCompletion"
                            onClick={() => this.handleDashboard()}
                            style={{
                                ...webStyle.btn1,
                                background: "#72EFDD",
                                color: '#0F172A',
                                fontWeight: 800
                            }}>
                            Go to dashboard
                        </button></Box></Box></Box>
        );
    }

    render() {
        const { open } = this.state;
        return (
            <>
                <Box style={webStyle.backgroundCLr}><Header navigation={this.props.navigation} id={"3"} />
                    <div style={styles.root} >
                        {open &&
                            <div className="sidenavHeader" style={styles.drawerHeader} >
                                <IconButton onClick={this.handleDrawerClose}><ChevronLeftIcon />
                                </IconButton>
                            </div>}
                        <Toolbar className="closedNav" style={open ? styles.hide : styles.show}><IconButton
                            color="inherit"

                            edge="start"
                            style={styles.menuButton}
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}>
                            <ChevronRightIcon /></IconButton>  </Toolbar>
                        <CssBaseline />
                        <Drawer

                            anchor="left"
                            className="scoreDrawer"
                            open={open}
                            style={styles.drawer}
                            variant="permanent"
                            PaperProps={{ style: open ? styles.drawerOpen : styles.drawerClose }}>
                            <AppBar
                                style={open ? { ...styles.appBar, ...styles.appBarShift } : styles.appBar}
                                className="navHeader"> </AppBar>
                            <Divider />
                            <ul>
                                <div className="navHeading">
                                    {open && <button className="goBackbtn" data-testid="goBack" onClick={this.handleDashboard}><ChevronLeftIcon /> </button>}
                                    <div className="navSubHead">
                                        <li className="coursename">
                                            <h5>{open && this.state.data && this.state.data.course_name}</h5></li>
                                        <li className="unitname">
                                            <h4>{open && this.state.data && this.state.data.unit_name}</h4></li>
                                    </div>
                                </div>
                                <Divider className="gradientHR" />

                           
                                <div className= {open ? `dropDownsection` : `dropDownsection dropDownsectionClose`} data-testid = "dropdownclass">
                                    {/* Render sections and lessons  */}
                                    {this.state.data && this.state.data?.topics?.map((section) => (
                                        <Accordion key={section.id} className="topicDropdown" data-testid="data-accord" defaultExpanded>

                                            <AccordionSummary
                                                expandIcon={<KeyboardArrowDownOutlined style={{ color: 'white' }} />}
                                                aria-controls="panel2-content"
                                               
                                                className="iconArrow"
                                                 id="panel2-header"
                                            >
                                                <Typography component="span">
                                                    <ListItemIcon style={{ color: "#fff" }}>

                                                    </ListItemIcon> <h5 className="sectionName">{open && section.name}</h5>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                {section.sections.data.map((lesson, index) => (
                                                    <div className={`sectionArea sectionviewresult ${lesson.attributes.marked_as_completed} ${lesson.attributes.completed}`}>

                                                        <Accordion className="sectionDropdown">
                                                            <AccordionSummary
                                                                aria-controls="panel2-content"
                                                                expandIcon={<ArrowDropDownIcon style={{ color: 'white' }} />}

                                                                id="panel2-header"
                                                            >
                                                                <Typography component="span">
                                                                    <List key={section.id}> <ListItem className="listData">
                                                                        <ListItemIcon
                                                                            className="iconIndex">
                                                                            <span>{index + 1}</span>
                                                                        </ListItemIcon>
                                                                        <p className="lessonName">{open && <ListItemText primary={lesson.attributes.name} />}</p>
                                                                    </ListItem>

                                                                    </List>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className="accDetail">
                                                                {lesson.attributes.lessons.data.map((lesson: any) => (

                                                                    <List key={lesson.id} className="listLesson"> <ListItem >

                                                                        <p className="smallLesson dataSmall">{open && <ListItemText primary={lesson.attributes.name} />}</p>
                                                                    </ListItem>

                                                                    </List>

                                                                ))}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                ))}
                                                <List>
                                                    <ListItem className="cal_Icon">
                                                    <ListItemIcon className= {`iconIndex ${section.assessment_attempted}`}>
                                                            <img src= {skip_exam2}/>
                                                        </ListItemIcon>
                                                        <p className="lessonName">
                                                            {open && <ListItemText primary="QuestionBank" />}
                                                        </p>
                                                    </ListItem>
                                                </List>


                                            </AccordionDetails>

                                           
                                        </Accordion>
                                    ))}
                                </div>
                            </ul><Divider /></Drawer>

                            <main style={open ? { ...styles.content, ...styles.contentShift } : styles.content}>

                            <div style={styles.drawerHeader} />
                            {!open && <button className="goBackbtn2" onClick={this.handleDashboard}>
                                <ChevronLeftIcon /></button>}{this.renderView()} </main></div>
                                <div className="footersmall"
          style={{ height: '45px', backgroundColor: '#050D15', marginBottom: '30px', display: 'flex', alignItems: 'center'}}>
          <div className="social-links-container">
              <p style={{  marginLeft: '100px' , color: 'rgba(255,255,255,.5)'}}>2024 MedEd Cloud. All rights reserved.</p>
          </div>
          </div>
                </Box></>
        );
    }
}
// Customizable Area Start
const webStyle = {

    root: {
        overflowX: "hidden" as const,
        display: "flex",
        flexWrap: "wrap" as const,
        textAlign: "end" as const,
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
        color: "white",

    },
    backgroundCLr: {
        backgroundColor: "rgb(28, 34, 53)",
        minHeight: "100vh",
    },

    content: {
        position: "relative" as const,
    },
    leftSidebar: {
        backgroundColor: "#050D15",
        width: "25%",
        height: "auto",
    },
    mainContent: {
        width: "100%",
    },
    container: {

        margin: "0 auto",
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        maxHeight: "100%",
        padding: "40px",
        width: "84%",
        height: "auto",
        background: "linear-gradient(315deg, #94A3B8, #BECBDF, #94A3B8)",
        borderRadius: "20px",
    },
    subcontainer: {

        margin: "0 auto",
        marginTop: '30px',
        overflowY: "auto" as const,
        scrollbarWidth: "none" as const,
        padding: "40px",
        height: "auto",
        background: "#CBD5E1",
        borderRadius: "20px",
        maxHeight: "100%",
    },

    description: {
        fontSize: "18px",
        textAlign: "justify" as const,
        letterSpacing: "0.7px",
        paddingTop: "10px",
    },
    titleContainer: {
        width: "fit-content",
        height: "43px",
        backgroundColor: "#64748B",
        justifyContent: "center" as const,
        alignItems: "center",
        borderRadius: "10px",
        display: "flex",
    },
    title: {
        fontFamily: 'Raleway',
        fontSize: "22px",
        padding: "16px",
        fontWeight: 'bold'
    },
    courseName: {

        position: "relative" as const,
        margin: "0 154px",
        display: "flex",
        alignItems: "center" as const,
        padding: "30px 0",
        flexDirection: "column" as const,
        gap: "10px",
    },
    btnBox: {

        display: "flex",
        flexDirection: "row" as const,
        justifyContent: "center",
        gap: "10px",
        width: "84%",
        padding: "40px",
        height: "auto",
        background: "#0F172A",
        borderRadius: "20px",
        margin: "30px auto",
    },
    btn1: {

        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        letterSpacing: "0.8px",
        width: "230px",
        cursor: 'pointer',
        height: "44px",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
        display: "flex",
    },
    btn2: {

        letterSpacing: "0.8px",
        fontWeight: 600,
        fontFamily: 'Raleway',
        cursor: 'pointer',
        width: "230px",
        height: "44px",
        background: "#72EFDD",
        border: "1px solid #72EFDD",
        borderRadius: "20px",
        fontSize: "16px",
    },
    videoContainer: {

        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginTop: "20px",
        display: "flex",
    },
    video: {
        width: "100%",
        maxWidth: "770px",
        borderRadius: "10px",
        backgroundColor: "transparent",
    },
    tabsContainer: {

        padding: "10px 0",
        marginBottom: "20px",
        display: "flex",
        gap: "10px",
        overflowX: "auto" as const,
    },

    question: {
        color: '#64748B',
        fontSize: '16px',
        marginTop: '10px',
        fontFamily: "poppins"
    },
    tab: {
        cursor: "pointer",
        fontSize: "16px",
        transition: "all 0.3s ease",
        whiteSpace: "nowrap" as const,
        padding: "8px 16px",
        borderRadius: "10px",
        border: "none",

    },
    questionScore: {

        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '35px',
        color: 'white',
        fontSize: '19px',
        fontWeight: 'bold',
        marginRight: '15px',
        fontFamily: 'poppins',
        display: 'flex',
        width: '99px',
        height: '43px',
        borderRadius: '15px'
    },

    imgDiv: {
        padding: "20px 0",
    },
    imgTag: {
        borderRadius: "10px",
    },
    questionMessage: {
        fontSize: '16px',
        marginTop: '10px',
        fontFamily: "poppins",
        fontWeight: 'bold',
        color: '#334155',

    },
    wholeContent: {

        marginTop: '30px',
        fontFamily: "poppins",
        fontSize: '18px',
        marginRight: '10px',
    },
};
// Customizable Area End
