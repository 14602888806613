// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { logoutSVG, medEdsmall } from "./assets";

import NavigationMenuController, { Props } from "./NavigationMenuController";
import Dashboard from '../../dashboard/src/Dashboard.web';
import Highlight from "../../dashboard/src/Highlight.web";

export default class InstitutionalHeader extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    }

  displayMenu = () => {
    const {
      anchorElHome,
      anchorElTools,
      anchorElhelp,
      selectedMenu,
       valueBorder,
      
    } = this.state;
    return (
      <div style={{ display: "flex",marginLeft:'20px' }}>
        <div
          style={{ position: "relative" }}
          data-test-id="onClickHome"
          onClick={() => this.onClickDashboard()}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              data-test-id="handleClickHome"
              className="displayMenuText"
              style={this.getMenuStyle(selectedMenu == 1, this.state.valueBorder==1,anchorElHome)}
            >
              Dashboard
            </Typography>
            {selectedMenu == 1 ? (
              <p
                style={{
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  backgroundColor:
                    anchorElHome || selectedMenu == 1
                      ? "white"                       
                      : "rgba(255, 255, 255, 0.4)",
                  color: "white",
                  marginLeft: "3px",                                   
                }}
              />
            ) : null}
          </Box>
        </div>
        <div style={{marginLeft:'-0.5rem'}}>
          <Typography
            onClick={(event) => this.onClickStudentList()}
            data-test-id="handleStudentList"
            className="displayMenuText"
            style={{
              ...this.getMenuStyle(selectedMenu == 2,valueBorder==2, anchorElHome),
              marginLeft: "auto",
            }}
          >
            Student list
          </Typography>
          {selectedMenu == 2 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor:
                  anchorElTools || selectedMenu == 2 
                    ? "white"
                    : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "50px",             
              }}
            />
          ) : null}
        </div>

        <div>
          <Typography
            onClick={(event) => this.onClickReports()}
            data-test-id="handleReports"
            className="displayMenuText"
            style={this.getMenuStyle(selectedMenu == 3, valueBorder==3,anchorElHome)}
          >
            Reports
          </Typography>
          {selectedMenu == 3 ? (
            <p
              style={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: anchorElhelp
                  ? "white"
                  : "rgba(255, 255, 255, 0.4)",
                color: "white",
                marginLeft: "38px",
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderNav = () => {
    return (
      <Grid container xl={12} className="headerAll" data-testid="nav">
        <Box className="headerMain">
          <Box className="logoContainer">
            <img className="logoTop" src={medEdsmall} alt="medEd_logo" />
          </Box>
          <Grid item xl={12} sm={6} className="displayMenu">
            {this.displayMenu()}
          </Grid>

          <Grid
            item
            xl={12}
            sm={6}
            className="searchContainer"
            data-testid="auth-wrapper"
          >
            <div className="searchBtn">
              <SearchIcon />
            </div>
            &nbsp;&nbsp;
            <Box className="header">
            <Box className="showProfile">
              <Box className="profileLetter">
                {this.state.userName?.charAt(0)}
              </Box>
              <div className="textContainer">
                <span className="usrNameTxt" data-testid="signupBtn">
                  {this.state.userName}
                </span>
                <span className="subTextProfile">Medicine</span>
              </div>
              <KeyboardArrowDownIcon
                onClick={this.toggleDropdown}
                className="dropdownIcon"
                data-test-id="dropdownArrow"
                style={{ cursor: 'pointer' }}
              />
              </Box>
              {this.state.isDropdownOpen && (
                <Box data-test-id="showlogout"onClick={this.handleLogout} style={{cursor:'pointer'}} className="showProfileLogout">
                  <div className="textContainer">
                    <span className="usrNameTxt" data-testid="signupBtn">
                      Logout
                    </span>
                  </div>
                </Box>
              )}
              </Box>
              <div className="searchBtn" onClick={this.handleLogout} id="handle-logout">
              <img src={logoutSVG} height={'15px'} width={'15px'}/>
            </div>
          </Grid>
        </Box>
      </Grid>
    );
  };

    render() {
    return (
            // Required for all blocks
      <Container maxWidth="xl" className="container-header mainheader">
        {this.renderNav()}
        {this.state.selectedMenu===1 && 
        <>
        <Dashboard navigation={undefined}/>
        <Highlight navigation={undefined} id={""}/>
        </>
      }
      </Container>
          );
  }
}

// Customizable Area End
