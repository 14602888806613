import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { library, schedule, } from "./assets";
import Footer from "./Footer.web";
import "./Header.css";
// Customizable Area End

import Catalogue1Controller, { Props } from "./Catalogue1Controller";
import Header from "./Header.web";
import SubHeader from "./SubHeader.web";

export default class Journey extends Catalogue1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { journeyView } = this.state;
    return (
      // Customizable Area Start
      <Box style={webStyle.backgroundCLr}>
        <Header navigation={this.props.navigation} id={"3"} />
        <SubHeader data={this.state.journeyView} navigation={this.props.navigation} id={this.props.id} onGoBack={this.onGoBack} />
        <Box     
          style={{
          overflowX: 'scroll',
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          marginTop: '10px',
          overflowY: 'hidden',
          margin: '20px',
          padding: '10px',
          paddingLeft: '35%',
          scrollBehavior: 'smooth',
        }}
        className="overall_card_journy">
          <Grid container spacing={4} style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {journeyView.attributes?.sub_categories?.data.map((journeyData: any) => (
              <Grid item lg={3} style={{ margin: 'auto',minWidth:'368px' }}>
                <Box className="cardParent" style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                  <>
                    <Box className={`${this.state.isHighLight === journeyData.id ? 'borderJourney cardMapContainer boxJourney' : 'cardMapContainer boxJourney'}`} data-id={journeyData.id} onClick={this.handleBorder}>
                      {/* <div className="comingsoon"><p>Coming Soon</p></div> */}
                      {this.state.loading ?
                        <Box style={{ textAlign: "center", margin: 'auto' }}>
                          <CircularProgress />
                        </Box>
                        :
                        <>
                          <Box style={{ position: "relative" }}>
                            {journeyData?.attributes.completed_percentage > 0 && (
                              <div className="percentCircle" style={{ position: 'absolute', top: '20px', left: '20px' }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                      zIndex: 999
                                    }}
                                  >
                                    <Box position="relative" display="inline-flex">
                                      <Box position="relative" display="inline-flex">
                                        <CircularProgress
                                          variant="determinate"
                                          value={100}
                                          style={{
                                            color: '#e0e0e0',
                                          }}
                                        />
                                        <CircularProgress
                                          variant="determinate"
                                          value={journeyData?.attributes.completed_percentage}
                                          style={{
                                            color: '#72EFDD',
                                            position: 'absolute',
                                            left: 0,
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Typography variant="caption" component="div" color="textSecondary" style={{ color: '#fff', fontFamily: 'Poppins', fontSize: '7px', fontWeight: 'bold' }}>{journeyData?.attributes.completed_percentage}%</Typography>
                                      </Box>
                                    </Box>
                                    <Typography
                                      style={{ color: "#fff", fontSize: "12px" }}
                                    >
                                      Complete
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="cardimagedata"
                              style={{
                                width: "100%",
                                height: "200px",
                                opacity: 0.5,
                                margin: "-4px 0px 0px",
                                backgroundImage: `url(${journeyData?.attributes.image.url})`,
                                position: "relative",
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                objectFit: 'cover'

                              }}
                            />

                            <Box
                              style={{
                                bottom: "24px",
                                left: "25px",
                                position: "absolute",
                                justifyContent: "space-between",
                                display: "flex",
                                width: "88%",
                              }}
                            >
                              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Typography className="cardTitle" style={{ fontSize: '15px', fontFamily: 'Raleway' }}>
                                  {journeyData?.attributes.name}
                                </Typography>

                              </Box>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              padding: "30px 20px",
                              borderBottomLeftRadius: "16px",
                              borderBottomRightRadius: "16px",
                              backgroundColor: "#172130",
                              margin: "-5px 5px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                marginTop: 10,
                                justifyContent: "space-between",
                              }}
                            >
                              <Box style={{ display: "flex" }}>
                                <img src={library}
                                  style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                                />
                                <Typography
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "rgba(255,255,255,0.6)",
                                    fontSize: "13px",
                                  }}
                                  className="font-family-p"
                                  data-test-id="unit-count"
                                >
                                  {`${journeyData?.attributes.all_units_count || 0} Unit${journeyData?.attributes.all_units_count > 1 ? "s" : ""}`}
                                </Typography>
                              </Box>
                              <Box
                                fontSize="small"
                                style={{ marginRight: 5, display: "flex", gap: 4 }}
                              >
                                <img src={schedule}
                                  style={{ color: "#fff", marginRight: 5, width: 18, height: 18 }}
                                />
                                <Typography
                                  style={{ color: "rgba(255,255,255,0.6)", fontSize: "13px", fontFamily: 'Poppins' }}
                                  className="font-family-p"
                                >
                                  {journeyData?.attributes.duration}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                marginTop: "20px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                              }}
                            >
                              <div className="course_desc" style={{ overflow: "auto", height: "auto", maxHeight: "130px", paddingRight:'12px' }}>
                                <Typography
                                  style={{ color: "rgba(255,255,255,0.6)", fontSize: "14px", textAlign: 'justify', fontFamily: 'Raleway' }}
                                >
                                  {journeyData?.attributes.description}
                                </Typography>
                              </div>
                            </Box>
                            <Box style={{ textAlign: "center" }} />
                          </Box>
                        </>
                      }
                    </Box>
                    {this.state.isHighLight === journeyData.id ?
                      <Button
                        className="quebankbtns getStartbtn"
                        variant="contained"
                        onClick={() => {
                          this.enrollInCourse(journeyData?.attributes?.id);
                          this.handleRedirectToCourse(journeyData?.attributes.id);
                        }}
                        style={{
                          width: '200px',
                          height: '44px'
                        }}
                      >
                        {journeyData?.attributes.completed_percentage == 0.0
                          ? "Get Started!"
                          : journeyData?.attributes.completed_percentage == 100
                            ? "Review"
                            : "Continue"}
                      </Button>
                      : ""}
                  </>
                </Box>
              </Grid>

            ))}

          </Grid>
        </Box>
        <Footer navigation={this.props.navigation} id={""} />
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  statsBodyy: {
    opacity: "30% !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  } as const,
  buttonStyle: {
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    width: "100%",
    height: "45px",
    marginTop: "40px",
  },
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px",
  },

  imgIconBack: {
    background: "rgba(51, 65, 85, 1)",
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundCLr: {
    backgroundColor: "rgb(28, 34, 53)",
  },
  plusBtnStyle: {
    background: "linear-gradient(90deg, #6200EA 0%, #26C5F3 100%)",
    color: "white",
    borderRadius: "50%",
    padding: 5,
    width: "32px !important",
    height: "32px !important",
  },
  imgQ: {
    width: 14,
    height: 20,
  },
  subHeadingText: {
    fontWeight: 700,
    fontSize: "20px",
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
