// TopicProgress.tsx
import React from "react";
import { Box } from "@material-ui/core";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";

interface Lesson {
  id: string;
  attributes: {
    completed?: boolean;
  };
}

interface TopicProgressProps {
  sections: Lesson[];
  currentIndex: number;
  onNavigate: (direction: "prev" | "next") => void;
  scrollProgress: number;
}

const getProgressBarWidth = (lesson: any,
  index: number,
  currentIndex: number,
  scrollProgress: number
) => {
  if(lesson?.attributes?.marked_as_completed|| lesson.attributes.completed )
    return "100%";
  if(index === currentIndex){
    return `${scrollProgress}%`;
  }
  return  "6%";
};


const getProgressBarColor = (lesson: any,
  index: number,
  currentIndex: number
) => {
  return lesson?.attributes?.marked_as_completed||lesson.attributes.completed || index === currentIndex ? "#72EFDD" : "#334155"; // Green if completed, gray otherwise
};


const getProgressBarBorder = (index: number, currentIndex: number) => {
  return index === currentIndex ? true : false;
};

const TopicProgress: React.FC<TopicProgressProps> = ({
  sections,
  currentIndex,
  onNavigate,
  scrollProgress,
}) => {
  return (
    <Box className="topic-progress" style={styles.progressWrapper}>
      <Box style={styles.innerWrapper}>
        <Box style={styles.progressContainer}>
          <KeyboardArrowLeftOutlined
            data-testid="prev-button"
            style={{
              ...styles.navIcon,
              cursor: currentIndex > 0 ? "pointer" : "not-allowed",
              opacity: currentIndex > 0 ? 1 : 0.5,
            }}
            onClick={() => currentIndex > 0 && onNavigate("prev")}
          />

          {sections.map((lesson, index) => (
            <Box
              key={lesson.id}
              style={styles.progressBar}
              data-testid="progress-bar-1"
            >
              <Box
                style={{
                  ...styles.progressTrack,
                  outline: getProgressBarBorder(index, currentIndex)
                    ? "1px solid #727c8b"
                    : "none",
                }}
              >
                <Box
                  data-testid={`progress-bar-${index}`}
                  style={{
                    ...styles.progressFill,
                    width: getProgressBarWidth(lesson, index, currentIndex, scrollProgress),
                    backgroundColor: getProgressBarColor(lesson, index, currentIndex),
                  }}
                />

              </Box>
            </Box>
          ))}

          <KeyboardArrowRightOutlined
            data-testid="next-button"
            style={{
              ...styles.navIcon,
              cursor:
                currentIndex < sections.length - 1 ? "pointer" : "not-allowed",
              opacity: currentIndex < sections.length - 1 ? 1 : 0.5,
            }}
            onClick={() =>
              currentIndex < sections.length - 1 && onNavigate("next")
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  innerWrapper: {
    maxWidth: "850px",
    margin: "0 auto",
  },
  progressWrapper: {
    width: "100%",
    padding: "20px 0",
    backgroundColor: "transparent",
  },
  progressContainer: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  progressBar: {
    width: "100%",
  },
  progressTrack: {
    backgroundColor: "#334155",
    borderRadius: "10px",
    overflow: "hidden",
    outlineOffset: "2px",
    height: "7px",
  },
  progressFill: {
    borderRadius: "10px",
    height: "100%",
    transition: "width 0.3s ease-out",
  },
  navIcon: {
    fontSize: "24px",
    color: "white",
    transition: "opacity 0.3s ease",
  },
};

export default TopicProgress;
