import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import React, { CSSProperties } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  btnVisible?: boolean;
  showMenu?: boolean;
  data?: any;
  onGoBack?: () => void
  // Customizable Area End
}

interface S {
    anchorElHome: boolean | null;
    valueBorder:number,
    isDropdownOpen:boolean;
    userName:string;
    selectedMenu: number;
    anchorElTools: boolean | null;
    anchorElServices: boolean | null;
    anchorElhelp: boolean | null;
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   menuMapping: Record<string, number> = {
    "/Catalogue1": 1,
    "/Scheduling": 2,
    "/KnowledgeTrainingCenter": 3,
  };
  
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
 /* istanbul ignore next */
   this.state={
    anchorElHome: null,
    userName:'',
    isDropdownOpen:false,
    anchorElTools: null,
    valueBorder:0,
    anchorElServices: null,
    anchorElhelp: null,
    
    selectedMenu:  this.menuMapping[window.location.pathname] ?? 3

   }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  getFontWeight = (isActive: any) => (isActive ? 500 : 300);
  getMenuStyle = (isSelected: boolean,checkborder:boolean, anchorEl: any): CSSProperties => {
    return {
      cursor: "pointer",
      color: isSelected ? "white" : "rgba(255, 255, 255, 1)",
      textAlign: "center",
      fontWeight: isSelected ||checkborder? 600 : this.getFontWeight(anchorEl),
      backgroundColor: checkborder ? "#FFFFFF0D" : "transparent",
      borderRadius: checkborder ? "20px" : "0",
      display: "inline-flex", 
      alignItems: "center",
      justifyContent: "center",
      marginRight: "20px",
      border: checkborder ? "1px solid #FFFFFF0D" : "1px solid transparent",
      padding: "8px 20px",
      lineHeight: "1.5",
      minWidth: "100px",
      width: "fit-content",
    };
  };
  handleClick = (event: any, menuType: string) => {
    if (menuType === "home") {
      this.removeIndex();
      this.setState({ anchorElHome: event.currentTarget,valueBorder:1 });
    } else if (menuType === "tools") {
      this.setState({ anchorElTools: event.currentTarget,valueBorder:2 });
    } else if (menuType === "services") {
      this.setState({ anchorElServices: event.currentTarget,valueBorder:3 });
    } else if (menuType === "help") {
      this.removeIndex();
      this.setState({ anchorElhelp: event.currentTarget,valueBorder:4});
    }
  };
  toggleDropdown = () => {
    this.setState((prevState):any => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };
  removeIndex=()=>{
    localStorage.removeItem("selectedIndex")
  }
  handleLogout = () => {
    removeStorageData("signupToken");
    this.removeIndex();
   this.props.navigation.navigate("Signuplogin")
  
  };
  componentDidMount = async () => {
    
    let usrName = await getStorageData("userName");
    this.setState({ userName: usrName });
  }
  onClickHome = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Catalogue1"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
 

  handleRedirectToCourse = (idredirect: any) => {
      this.props.navigation.navigate("Course", { courseID:idredirect });
    
  }
  handleColor=(value:any)=>{
    localStorage.setItem("selectedIndex",value) 
  }
  handleScheduleClick = () => {
    localStorage.setItem("selectedIndex","10")
    this.props.navigation.navigate("Scheduling");
  };

  onMenuItemClick = () => {
    const navigationMenu= new Message(getName(MessageEnum.NavigationMessage));
    navigationMenu.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KnowledgeTrainingCenter"
    );
    navigationMenu.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMenu);
  };

  goToTermsAndConditions = () => {
    this.props.navigation.navigate("TermsAndConditions");
  };

}