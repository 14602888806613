import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import HighlightController, {
  Props,
} from "./HighlightController.web";
import "./Header.css";
import { bookLight ,courseimage,timer} from "./assets";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
export default class Highlight extends HighlightController {
  constructor(props: Props) {
    super(props);

  }
  CoursesData = [
    {
      "id": "21",
      "type": "category",
      "attributes": {
        "id": 21,
        "name": "Genetics",
        "is_active": true,
        "level": 1,
        "description": "Investigates genetic foundations, from molecular mechanisms to their impact on organisms",
        "image": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cd4243d6ef171701ab6f956dae57846bda403b77/Building%20Blocks%20of%20Medical%20Knowledge.jpg"
        },
        "completed_courses": 2,
        "completed_percentage": 25,
        "completed_score": 85,
        "all_courses_count": "2/10",
        "time": "12h 30min",
        "parent_category_name": "Molecular Innovations",
       
      }
    },
    {
      "id": "22",
      "type": "category",
      "attributes": {
        "id": 21,
        "name": "Advanced Genetics",
        "is_active": true,
        "level": 3,
        "description": "Explores advanced genetics, including epigenetics and CRISPR-Cas9, and their applications in medicine and biotech.",
        "image": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cd4243d6ef171701ab6f956dae57846bda403b77/Building%20Blocks%20of%20Medical%20Knowledge.jpg"
        },
        "completed_courses": 2,
        "completed_percentage": 35,
        "completed_score": 8,
        "all_courses_count": "17",
        "time": "23h",
        "parent_category_name": "Biotechnology",
       

      }
    },
    {
      "id": "24",
      "type": "category",
      "attributes": {
        "id": 24,
        "name": "Genetic Disease Management",
        "is_active": true,
        "level": 1,
        "description": "Delves into genetic influences on health, emphasizing diagnostics, treatment strategies, and ethical considerations.",
        "image": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cd4243d6ef171701ab6f956dae57846bda403b77/Building%20Blocks%20of%20Medical%20Knowledge.jpg"
        },
        "completed_courses": 2,
        "completed_percentage": 55,
        "completed_score": 86.4,
        "time": "22h",
        "all_courses_count": "5",
        "parent_category_name": "Foundational Sciences",
      }
    },

    {
      "id": "21",
      "type": "category",
      "attributes": {
        "id": 21,
        "name": "Genetics",
        "is_active": true,
        "level": 1,
        "description": "Investigates genetic foundations, from molecular mechanisms to their impact on organisms",
        "image": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cd4243d6ef171701ab6f956dae57846bda403b77/Building%20Blocks%20of%20Medical%20Knowledge.jpg"
        },
        "completed_courses": 2,
        "completed_percentage": 95,
        "completed_score": 95,
        "all_courses_count": "2/10",
        "time": "12h 30min",
        "parent_category_name": "Molecular Innovations",
        "parent_category_icon": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcDBCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--97280c75a3919d62535bc4379ae6b877b824e744/Foundational%20Sciences.png"
        },

      }
    },
    {
      "id": "22",
      "type": "category",
      "attributes": {
        "id": 21,
        "name": "Advanced Genetics",
        "is_active": true,
        "level": 3,
        "description": "Explores advanced genetics, including epigenetics and CRISPR-Cas9, and their applications in medicine and biotech.",
        "image": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cd4243d6ef171701ab6f956dae57846bda403b77/Building%20Blocks%20of%20Medical%20Knowledge.jpg"
        },
        "completed_courses": 2,
        "completed_percentage": 25,
        "completed_score": 85,
        "all_courses_count": "17",
        "time": "23h",
        "parent_category_name": "Biotechnology",
        "parent_category_icon": {
          "url": "https://bcforraji-414038-ruby.b414038.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcDBCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--97280c75a3919d62535bc4379ae6b877b824e744/Foundational%20Sciences.png"
        },

      }
    },
 
  ]
  coursesHighlight = () => {
    return (
      <Box
        className="highlightMainContainer"
        style={{ marginTop: "50px" }}>
        <Box style={webStyle.rowDirection}>
          <h4 datat-test-id="clicknext" style={{ ...webStyle.subHeadingText, marginLeft: "10px", marginBottom: '20px' }}
          >
            Course Performance
          </h4>
        </Box>
        {
          <Grid
            container
            spacing={3}
            className="CradBox overall_card knowledge"
            data-test-id="recommendedCard"
            style={{
              overflowX: "auto",
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "start",
              marginTop: "10px",
              scrollbarWidth: "thin",
              scrollbarColor: "#1E293B transparent",
            }}

          >
            <>
              {this.CoursesData?.map(
                (card: any, index: any) => {
                  const highlightCard = card.attributes;
                  return (
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ flexShrink: 0 }}
                      key={index}
                      data-testid={`card-know-med-${index}`}
                    >
                      <Box className="cardMapContainer" style={{ width: "100%" }}>
                        <Box style={{ position: "relative", padding: '0px 5px' }}>
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                              objectFit: "cover",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              opacity: "0.5",
                            }}
                            src={courseimage}
                            alt="Our Image"
                          />
                          <Box
                            style={{
                              position: "absolute",
                              bottom: "24px",
                              left: "25px",
                              width: "88%",
                              justifyContent: "space-between",
                              display: "flex",
                            }}
                          >
                            <Box>
                              <div style={{ display: "flex" }}>
                              {highlightCard?.parent_category_icon?.url &&
                                <div
                                  style={{
                                    background: "rgba(51, 65, 85, 0.6)",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                 
                                  <img
                                    src={highlightCard?.parent_category_icon?.url}
                                    style={{
                                      color: "white",
                                      width: "90%",
                                      height: "90%",
                                      objectFit: "contain",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>}
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    color: "rgba(255, 255, 255, 0.6)",
                                    marginLeft: 5,
                                    textTransform: "uppercase",
                                    fontFamily:'Raleway'
                                  }}
                                  variant="body1"
                                >
                                  {highlightCard?.parent_category_name}
                                </Typography>
                              </div>

                              <Box>
                                <Typography
                                  style={{ minHeight: "60px" ,fontFamily:'Raleway',fontWeight:900,fontSize:'20px'}}
                                >
                                  {highlightCard?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#172130",
                            margin: '10px',
                            marginBottom:'0px',
                            borderBottomLeftRadius: "16px",
                            borderBottomRightRadius: "16px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 10,
                            }}
                          >
                            <Box style={{ display: "flex" }}>
                              <img
                                src={bookLight}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontFamily: "Poppins",
                                  fontSize: "13px",
                                }}
                              >
                                {`${highlightCard?.all_courses_count} units`}
                              </Typography>

                            </Box>
                            <Box style={{display:'flex'}}>
                            <img
                                src={timer}
                                style={{
                                  color: "#fff",
                                  marginRight: 5,
                                  width: 18,
                                  height: 18,
                                }}
                              />
                            <Typography
                              style={{
                                color: "rgba(255,255,255,0.6)",
                                fontFamily: "Poppins",
                                fontSize: "13px",
                              }}
                            >
                              {`${highlightCard?.time}`}
                            </Typography>
                            </Box>
                            <Box
                              fontSize="small"
                              style={{ display: "flex", marginRight: 5, gap: 4 }}
                            >
                              <PeopleOutlineOutlinedIcon
                                  style={{ color: "white" }}
                                  fontSize="small"
                                />
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "13px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Level {highlightCard?.level}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              marginTop: "20px",
                              flexDirection: "column",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="course_desc"
                              style={{
                                overflow: "auto", height: "80px", scrollbarWidth: "thin",
                                scrollbarColor: "#1E293B transparent",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "14px",
                                  fontFamily:'Raleway',
                                  fontWeight:300
                                }}
                              >
                                {highlightCard?.description}
                              </Typography>
                            </div>
                                <div
                                  style={{marginBottom:'10px',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 10,
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "44px",
                                        height: "44px",
                                        borderRadius: "50%",
                                        position: "relative",
                                        background: `conic-gradient(
                            #5591F5 0% ${highlightCard?.completed_percentage}%, 
                            rgba(255,255,255,.1) ${highlightCard?.completed_percentage}% 100% 
                          )`,
                                      }}
                                    >
                                      <div className="donut-hole-2" />
                                      <div className="donut-data" style={{ fontFamily: "Poppins"}}>
                                        {highlightCard?.completed_percentage}%
                                      </div>
                                    </div>
                                    <Typography
                                      style={{ color: "#fff", fontSize: "12px", fontFamily: "Poppins" }}
                                    >
                                      Complete
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "44px",
                                          height: "44px",
                                          borderRadius: "50%",
                                          position: "relative",
                                          background: `conic-gradient(
                          #5591F5 0% ${highlightCard?.completed_score}%, 
                          rgba(255,255,255,.1) ${highlightCard?.completed_score}% 100% 
                        )`,
                                        }}
                                      >
                                      <div className="donut-hole-2" />
                                        <div className="donut-data" style={{ fontFamily: "Poppins",}}>
                                          {highlightCard?.completed_score}%
                                        </div>
                                      </div>
                                      <Typography
                                        style={{ color: "#fff", fontSize: "12px", fontFamily: "Poppins", }}
                                      >
                                        Average Score
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </>
          </Grid>
        }
      </Box>
    );
  };
  render() {
    return (
      this.coursesHighlight()
    );
  }
 }

const webStyle = {
  rowDirection: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingText: {
    fontFamily:'Raleway',
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(255,255,255,1)",
  },
};
// Customizable Area End
